<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <loading-indicator-view v-show="saving" :title="$t('signup.create_your_account.setting_up_your_account')" />

    <sign-up-view
      v-show="!saving"
      :step="1"
      :title="$t('signup.create_your_account.title')"
      :subtitle="$t('signup.create_your_account.subtitle')"
      v-on="$listeners"
    >
      <div class="w-full sm:w-[580px] text-center mb-8">
        <p class="text-label-medium mb-4">
          {{ $t('signup.create_your_account.info_text_1', { days: trialPeriodDays }) }}
        </p>
        <p class="text-label-medium">
          {{ $t('signup.create_your_account.info_text_2', { price: monthlyPrice }) }}
        </p>
      </div>
      <div class="w-full sm:w-[420px]">
        <form class="flex flex-col gap-6 bg-surface-min-contrast rounded mt-12 p-6">
          <text-input
            v-model="store.name"
            :placeholder="$t('attributes.full_name')"
            :floating-label="true"
            :error="v$.name.$error"
            autocomplete="name"
            :auto-focus="true"
          />
          <text-input
            v-model="store.email"
            type="email"
            :placeholder="$t('attributes.email')"
            :floating-label="true"
            :error="v$.email.$error"
            :validation-message="vuelidateExternalResults.email"
            autocomplete="email"
            @input="vuelidateExternalResults.email = null"
          />
          <text-input
            v-model="store.password"
            type="password"
            class="password-field"
            :placeholder="$t('attributes.password')"
            :floating-label="true"
            :error="v$.password.$error"
            :invalid="v$.password.$invalid"
            :validation-message="$t('passwords.change.error_more_complex')"
            autocomplete="new-password"
          />
          <country-select
            v-model="store.country"
            :allow-null="false"
            :floating-label="true"
            :error="v$.country.$error"
            :label="$t('signup.select_country.your_country')"
          />
          <text-input
            v-model="store.sign_up_code"
            type="text"
            :success="signUpCode"
            :error="signUpCodeInvalid"
            :validation-message="signUpCodeValidationMessage"
            :placeholder="t('signup.enter_promo_code')"
            :floating-label="true"
          />
        </form>
        <div class="mt-12 px-4 flex flex-col gap-4">
          <label class="flex text-label-medium text-left">
            <checkbox
              class="mr-2"
              :checked="store.accept_terms"
              :error="v$.accept_terms.$error"
              @change="store.accept_terms = !store.accept_terms"
            />
            <span :class="{ 'text-semantic-error': v$.accept_terms.$error }" v-html="acceptTermsHtml"></span>
          </label>
          <label class="flex text-label-medium text-left">
            <checkbox
              class="mr-2"
              :checked="store.verified_medical_professional"
              :error="v$.verified_medical_professional.$error"
              @change="store.verified_medical_professional = !store.verified_medical_professional"
            />
            <span
              class="sm:whitespace-pre-wrap"
              :class="{ 'text-semantic-error': v$.verified_medical_professional.$error }"
            >
              {{ $t("signup.verify_label") }}*
            </span>
          </label>
        </div>
        <div class="mt-12">
          <button class="btn-primary" @click="createAccount">
            {{ $t("signup.join_osgenic") }}
          </button>
        </div>
      </div>
    </sign-up-view>
  </div>
</template>

<script setup lang="ts">
import SignUpView from "./sign-up-view.vue";
import { required, email, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useSignUpStore } from "./signup-store";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import { ref } from "vue";
import Users from "@/services/users";
import { getLocale } from "@/i18n";
import User from "@/model/user";
import { UnprocessableEntityError } from "@/api/api-error";
import { reactive } from "vue";
import { useI18n } from "vue-i18n-bridge";
import { useRouter } from "@/composables/router";
import { useToaster } from "@/composables/toaster";
import { useRollbar } from "@/composables/rollbar";
import { computed } from "vue";
import { getCurrentTzCountry } from "@/countries";
import { useSignUpCode } from "@/api/sign-up-codes";
import { storeToRefs } from "pinia";
import SubscriptionsService from "@/services/subscriptions-service";
import { usePlanPrices } from "@/api/plan-prices";
import { priceToDisplay } from "@/model/plan-price";

const store = useSignUpStore();

const toaster = useToaster();

const rollbar = useRollbar();

const router = useRouter();

const rules = {
  name: { required },
  email: { required, email, unique: () => !vuelidateExternalResults.email },
  password: {
    required,
    minLength: minLength(8),
    hasNumberAndLetter: (password) => /\d/.test(password) && /\D/.test(password),
  },
  country: { required },
  verified_medical_professional: { isChecked: (value) => value === true },
  accept_terms: { isChecked: (value) => value === true },
  sign_up_code: { required: false },
};

const v$ = useVuelidate(rules, store);

const saving = ref(false);

const vuelidateExternalResults = reactive({
  email: null,
});

const { t } = useI18n();

const storeRefs = storeToRefs(store);

const { data: signUpCode } = useSignUpCode(storeRefs.sign_up_code);

const signUpCodeInvalid = computed(() => !!store.sign_up_code && store.sign_up_code.length > 0 && !signUpCode.value);

const signUpCodeValidationMessage = computed(() => {
  if (signUpCode.value) {
    return t("signup.valid_code");
  } else if (signUpCodeInvalid.value) {
    return t("errors.sign_up_code_not_valid");
  } else {
    return null;
  }
});

const acceptTermsHtml = computed(() => {
  const terms = "<a href='/eula' class='underline' target='_blank'>" + t("pages.terms_of_service") + "</a>";
  const privacy_policy =
    "<a href='/privacy_statement' class='underline' target='_blank'>" + t("pages.privacy_policy") + "</a>";
  return "<span>" + t("signup.accept_terms", { terms, privacy_policy }) + " *</span>";
});

const { data: plans } = usePlanPrices();

const trialPeriodDays = computed(() => signUpCode.value?.trial_period_days || 14);

const monthlyPrice = computed(() => {
  const monthlyPlan = plans.value?.find(({ period }) => period === "month");
  return monthlyPlan ? priceToDisplay(monthlyPlan) : "";
});

const createAccount = async () => {
  const isValid = await v$.value.$validate();
  if (isValid) {
    saving.value = true;
    try {
      // Validate signup code first if provided
      if (store.sign_up_code) {
        const isValidCode = await new Users().validateSignUpCode(store.sign_up_code);
        if (!isValidCode) {
          vuelidateExternalResults.sign_up_code = t("errors.sign_up_code_not_valid");
          return;
        }
      }

      const attrs: Partial<User> = {
        name: store.name,
        email: store.email,
        password: store.password,
        password_confirmation: store.password,
        country: store.country,
        language: getLocale(),
        verified_medical_professional: store.verified_medical_professional,
        sign_up_code: store.sign_up_code, // Pass the code to user creation
      };

      const user = await new Users().create(attrs);
      store.authentication_token = user.authentication_token;

      // Get the monthly plan
      const monthlyPlan = plans.value?.find(({ period }) => period === "month");

      if (monthlyPlan) {
        const subscriptionService = new SubscriptionsService();
        await subscriptionService.create(
          {
            plan_price_id: monthlyPlan.price_id,
          },
          {
            authenticationToken: store.authentication_token,
          }
        );
        store.payment_method = "card";
      }

      router.push({ name: "SignupWelcome" });
    } catch (error) {
      if (error instanceof UnprocessableEntityError) {
        const details = error.details || {};
        for (let field in vuelidateExternalResults) {
          vuelidateExternalResults[field] = details[field] || null;
        }
      } else {
        const message = t("signup.account_creation_failed");
        toaster.error(message, { message, position: "top-right" });
        rollbar.error(error);
      }
    } finally {
      saving.value = false;
    }
  }
};

if (!store.country) {
  store.country = getCurrentTzCountry();
}
</script>
