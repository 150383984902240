<template>
  <div class="modal z-50 flex flex-col pointer-events-none">
    <div class="w-full flex flex-col items-center my-auto pointer-events-auto">
      <slot name="content">
        <div class="content p-4 sm:px-8" :class="contentClasses">
          <div
            v-if="closable"
            class="close-button"
            :class="{ disabled: disableClose }"
            @click.prevent="handleClose"
          >
            <icon-fluent class="mr-[-5px] mt-[-5px]" name="dismiss"></icon-fluent>
          </div>
          <slot></slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Modal extends Vue {
  @Prop({ default: "small" })
  size: "auto" | "small" | "large" | "medium";

  @Prop()
  closable: boolean;

  @Prop({ default: false })
  disableClose: boolean;

  @Prop({ default: true })
  centered: boolean;

  @Prop({ default: "dark" })
  overlayMode: "dark" | "light";

  @Prop({ default: "" })
  contentClass: string;

  get contentClasses() {
    return [this.contentClass, this.size].join(" ").trim();
  }

  created() {
    document.body.classList.add("overflow-hidden");
  }

  mounted() {
    window.addEventListener('popstate', this.removeOverflowHidden);
  }

  handleClose() {
    if (!this.disableClose) {
      this.$emit('close');
    }
  }

  removeOverflowHidden() {
    document.body.classList.remove("overflow-hidden");
  }

  beforeDestroy() {
    document.body.classList.remove("overflow-hidden");
    window.removeEventListener('popstate', this.removeOverflowHidden);
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing);
    color: var(--ui-color-text-variant);
    line-height: 1;

    &:hover {
      cursor: pointer;
      color: var(--ui-color-text-primary);
    }

    &.disabled {
      color: var(--ui-color-text-variant);

      &:hover {
        cursor: default;
      }
    }
  }

  .content {
    position: relative;
    background-color: var(--ui-color-surface-min-contrast);
    max-width: 100%;
    border-radius: var(--border-radius);
    margin: var(--spacing);
    box-shadow: 0px 3px 6px 2px rgb(0 0 0 / 6%);
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.3));
    max-height: calc(100vh - 2 * var(--spacing));
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.small {
      width: 400px;
    }

    &.medium {
      width: 600px;
    }

    &.large {
      width: 800px;
    }

    @media (max-width: $screen-sm-max) {
      border-radius: $border-radius-sm;
      margin: $spacing;
      &,
      &.medium,
      &.large {
        width: calc(100% - 2 * #{$spacing});
      }
    }
  }
}
:root {
  &:not(.dark) {
    .modal {
      &.light {
        background-color: rgba(255, 255, 255, 0.6);

        .content {
          background-color: var(--ui-color-surface-min-contrast);
        }
      }
    }
  }
}
</style>
